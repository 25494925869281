<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import General from '@/services/General';
    import modalAddAppCrmMimic from '@/components/modals/app/modalAddAppCrmMimic';
    import Swal from "sweetalert2";


    export default {
        components: { Layout, PageHeader  , modalAddAppCrmMimic},
        page: {
            title: "Application Crm Mimic",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                title: "Application Crm Mimic",
                items: [
                    {
                        text: "Application",
                        href: "/app",
                    },
                    {
                        text: "Application Crm Mimic",
                        active: true,
                    },
                ],
                showLoader:false,
                error: null,
                showModal: false,
                modalData: [],
                link_id: null,
                results: {},
                email : '' ,
                form: {
                    csrf_token: localStorage.getItem('csrf_token'),
                    selectedKey: "",
                    selectedValue: "",
                },

            };
        },
        mounted() {
            this.getAppCrmMimic();
        },
        methods: {

            modalAddAppCrmMimic(){
                this.$bvModal.show("add_app_crm_mink")
            },
            
            async getAppCrmMimic() {
                try {
                    this.showLoader = true
                    this.toggleBusy();
                    const response = await General.getAppCrmMimic();
                    this.modalData = response.data.data;
                    this.toggleBusy();
                    this.showLoader = false;
                }
                catch (error) {
                    this.toggleBusy();
                }
            },

            deleteItem(id) {
                Swal.fire({
                    title: "Remove this Mimic?",
                    text: "This mimic will be removed!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Remove!",
                }).then((result) => {
                    if (result.value) {
                        try {
                            General.deleteAppCrmMimic(id)
                                .then((response) => {
                                    const res = response.data ? response.data : false;
                                    const error = response.data.error ? response.data.error : 'Failed';
                                    if (res) {
                                        this.getAppCrmMimic();
                                        Swal.fire("Remove Mimic", "Application Crm Mimic is successfully removed !", "success");
                                    } else {
                                        Swal.fire("Fail!", error, "warning");
                                    }
                                })
                        } catch (error) {
                            this.error = error.response.data.error ? error.response.data.error : "";
                        }
                    }
                });
            }
        },
        middleware: "authentication",
    };
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mt-4">
                            <div class="col-sm-4">
                                <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light" @click="modalAddAppCrmMimic">
                                    <i class="mdi mdi-plus me-2"></i>
                                    Add Application Crm Mimic
                                </a>
                            </div>

                            <div class="col-12" v-if="!showLoader">
                                <div class="col-sm-12 col-md-12 mb-5">
                                    <div id="tickets-table_length" class="dataTables_length">

                                            <div class="row">
                                                <div class="col-2"><label  style="font-size: 20px;">User email </label></div>
                                                <div class="col-3"><label  style="font-size: 20px;">Mimic email </label></div>
                                            </div>
                                            <div v-for="(params, index) in modalData" :key="index">
                                                <div class="row">
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <div class="form-group">
                                                                <span :id="'key_' + index" >{{params.customer_email}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <div class="form-group">
                                                                <span>{{params.mimic_email}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-1">
                                                        <button class="btn btn-danger" @click="deleteItem(params.customer_id)">Delete</button>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body d-flex align-items-center justify-content-center" v-else>
                                <b-spinner large></b-spinner>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
 <modalAddAppCrmMimic  @onRefresh="getAppCrmMimic"></modalAddAppCrmMimic>
    </Layout>
</template>