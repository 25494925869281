<script>
    import General from '@/services/General'
    import validationMessages from '@/components/validations'
    import Customers from '@/services/Customers';
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
    import _ from 'underscore';
    import { required, minLength, numeric } from "vuelidate/lib/validators"
    export default {
        components:{
            VueBootstrapTypeahead,
            validationMessages,
        },
        data() {
            return {
                showModal: false,
                tryingToSubmit: false,
                submitted: false,
                date: new Date(),
                email: '',
                csrf_token: localStorage.getItem('csrf_token') ,
                filterInput:{
                    email:'',
                    email_mimic: '' ,
                },
                customerData:[],
                customerDataMimic: [],
                selectedEmail : '',
                selectedEmailMimic : '' ,
                selectedCustomerMimic : '' ,
                selectedCustomer : '' ,
            };
        },
        watch: {
            'filterInput.email': _.debounce(function(search) { this.getCustomersAutocomplete(search, 'email')}, 500),
            'filterInput.email_mimic': _.debounce(function(search) { this.getCustomersAutocomplete(search, 'email_mimic')}, 500),
        },
        validations: {
            filterInput: {
                email: { required },
                email_mimic: { required }
            }
        },
        methods: {

            refreshData() {
                this.$emit('onRefresh') //event from parent
            },


            async getCustomersAutocomplete(query, inputTypeName) {
                this.customerData = [];
                if(query.trim().length > 0){
                    if(inputTypeName == 'email'){
                        query = 'email='+query
                    }else if(inputTypeName == 'email_mimic'){
                        query = 'email='+query
                    }
                    const response = await Customers.getCustomersByFilter(query);
                    const rawData = await response;
                    var suggestions = [];
                    var suggestionsMimic = [];
                    rawData.data.data.forEach(function (item) {
                        if(item.email && inputTypeName === 'email'){
                            suggestions.push({
                                text: item.email,
                                customer_id: item.customer_id
                            });
                        }
                        if(item.email && inputTypeName === 'email_mimic'){
                            suggestionsMimic.push({
                                text: item.email,
                                customer_id: item.customer_id
                            });
                        }
                    });

                    this.customerData = suggestions.filter((value, index, self) => self.map(x => x.text).indexOf(value.text) == index)
                    this.customerDataMimic = suggestionsMimic.filter((value, index, self) => self.map(x => x.text).indexOf(value.text) == index)
                }
            },
            async handleEmailSelection(email) {
                const selectedCustomer      = this.customerData.find(customer => customer.text === email);
                const selectedCustomerMimic = this.customerDataMimic.find(customer => customer.text === email);

                if (selectedCustomer) {
                    this.selectedEmail = {
                        text: selectedCustomer.text,
                        id: selectedCustomer.customer_id
                    };
                }
                if (selectedCustomerMimic) {
                    this.selectedEmailMimic = {
                        text: selectedCustomerMimic.text,
                        id: selectedCustomerMimic.customer_id
                    };
                }
            },
            createAppCrmMink(){

                this.tryingToSubmit = true;
                this.submitted = true;
                this.$v.$touch();

                if (this.$v.$invalid) {
                    this.tryingToSubmit = false;
                    return;
                }

                const formData = {
                    customer_id: this.selectedEmail.id,
                    mimic_customer_id: this.selectedEmailMimic.id
                };

                General.createAppCrmMimic(formData)
                    .then((response) => {
                        this.successmsg('Change has been saved');
                        this.closeModal();
                        this.refreshData();
                    })
                    .catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "An error occurred.";
                        this.failedmsg(this.error);
                    })
                    .finally(() => {
                        this.tryingToSubmit = false;
                    });

            },
            closeModal() {
                this.showModal = false;
                this.selectedCustomer ='' ;
                this.selectedCustomerMimic ='';
                this.selectedEmail = '';
                this.selectedEmailMimic = '';
            },

            resetProps(){
                this.submitted = false;
                this.selectedCustomer ='' ;
                this.selectedCustomerMimic ='';
                this.selectedEmail = '';
                this.selectedEmailMimic = '';
            },
        }
    };
</script>
<template>
    <b-modal id="add_app_crm_mink" size="lg" v-model="showModal" @hidden="resetProps" title="Add New App Crm Mimic" title-class="font-18">
        <form @submit.prevent="createAppCrmMink">
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <div class="form-group mb-6">
                        <b-form-group label="Email" label-for="filterInput.email" class="mb-9">
                            <vue-bootstrap-typeahead id="filterInput.email" ref="emailAutocomplete"
                                                     v-model.trim="filterInput.email"
                                                     :data="customerData"
                                                     :serializer="s => s.text"
                                                     @input="handleEmailSelection"
                                                     :class="{
                                            'is-invalid': submitted && $v.filterInput.email.$error,
                                            }"
                            />
                        </b-form-group>
                        <validationMessages v-if="submitted" :fieldName="'Email'" :validationName="$v.filterInput.email"></validationMessages>

                    </div>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="form-group mb-6">
                        <b-form-group label="Mimic Email" label-for="filterInput.email_mimic" class="mb-9">
                            <vue-bootstrap-typeahead id="filterInput.email_mimic" ref="emailAutocompleteMimic"
                                                     v-model.trim="filterInput.email_mimic"
                                                     :data="customerDataMimic"
                                                     :serializer="s => s.text"
                                                     @input="handleEmailSelection"
                                                     :class="{
                                            'is-invalid': submitted && $v.filterInput.email_mimic.$error,
                                            }"
                            />
                        </b-form-group>
                        <validationMessages v-if="submitted" :fieldName="'Email'" :validationName="$v.filterInput.email_mimic"></validationMessages>
                    </div>
                </div>
            </div>
            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="createAppCrmMink" :disabled="tryingToSubmit">
                <b-spinner v-show="tryingToSubmit" small></b-spinner>
                Submit
            </b-button>
        </template>
    </b-modal>
</template>